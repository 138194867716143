import React from "react"

import SEO from "../components/seo"
import { isBrowser } from "../components/lib/utils"
import { useAuth } from "../components/lib-admin/auth"

import Loadable from "react-loadable"
import Loader from "../components/lib-admin/components/Loader"

const SignInContainer = Loadable({
  loader: () => import("../components/container/signin"),
  loading() {
    return <Loader height={300} />
  },
  delay: 150,
})

const Container = Loadable({
  loader: () => import("../components/container/helpdesk"),
  loading() {
    return <Loader height={300} />
  },
  delay: 150,
})

const IndexPage = () => {
  const auth = useAuth()
  return (
    <React.Fragment>
      <SEO title="Helpdesk" />
      {isBrowser() === true && auth.isAuth() === true ? (
        <Container />
      ) : (
        <SignInContainer />
      )}
    </React.Fragment>
  )
}

export default IndexPage
